<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import type { ButtonVariants } from '../types'
    import { concatClasses } from '../utils'
    import Loader from './Loader.svelte'

    interface Props extends HTMLAttributes<HTMLElement> {
        active?: boolean
        disabled?: boolean
        href?: string
        loading?: boolean
        size?: '' | 'sm' | 'lg' | 'icon'
        variant?: ButtonVariants
        target?: string
        type?: 'button' | 'submit' | 'reset'
        rel?: string
        children: Snippet
    }

    const {
        class: classNames = '',
        active = false,
        disabled = false,
        href = '',
        loading = false,
        size = '',
        type = 'button',
        variant = 'primary',
        target = undefined,
        rel = undefined,
        children,
        ...rest
    }: Props = $props()

    const classes = $derived(
        concatClasses([
            'button',
            `button-${variant}`,
            size ? `button-${size}` : '',
            loading ? 'button-loading' : '',
            active ? 'active' : '',
            classNames,
        ])
    )
</script>

{#if href}
    <a class={classes} {href} {...rest} {rel} {target}>
        {@render children()}
    </a>
{:else}
    <button class={classes} disabled={disabled || undefined} {type} {...rest}>
        {#if loading}
            {@render children()}
            <Loader size={24} />
        {:else}
            {@render children()}
        {/if}
    </button>
{/if}

<style global lang="postcss">
    .button {
        display: inline-flex;
        gap: theme('spacing.2');
        align-items: center;
        justify-content: center;
        padding: theme('spacing.3') theme('spacing.6');
        font-weight: theme('fontWeight.normal');
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        color: theme('colors.white');
        border-radius: theme('borderRadius.DEFAULT');
        background-color: theme('colors.transparent');
        user-select: none;
        cursor: pointer;

        /* prettier-ignore */
        transition:
            theme('transitionDuration.DEFAULT')
            theme('transitionTimingFunction.DEFAULT')
            theme('transitionProperty.colors'),
            theme('transitionDuration.DEFAULT')
            theme('transitionTimingFunction.DEFAULT')
            theme('transitionProperty.shadow');
    }

    .button[disabled],
    .button.disabled {
        filter: brightness(1.5) grayscale(0.9);
        pointer-events: none;
    }

    .button.button-loading {
        position: relative;
        pointer-events: none;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: theme('borderRadius.DEFAULT');
        }

        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.5rem;
            height: 1.5rem;
            transform: translate(-50%, -50%);
        }
    }

    .button.button-lg {
        padding: theme('spacing.2') theme('spacing.4');
        font-size: clamp(1.15rem, 1.135rem + 0.07vw, 1.2rem);

        @screen sm {
            padding: theme('spacing.2') theme('spacing.6');
        }

        @screen md {
            padding: theme('spacing.3') theme('spacing.8');
        }

        @screen lg {
            padding: theme('spacing.4') theme('spacing.8');
        }
    }

    .button.button-sm {
        padding: theme('spacing.2') theme('spacing.4');
        border-radius: theme('borderRadius.sm');

        &::before {
            border-radius: theme('borderRadius.DEFAULT');
        }
    }

    .button.button-icon {
        padding: 0 theme('spacing.1');
        line-height: 0;

        svg {
            width: theme('spacing.4');
        }
    }

    .button.button-primary {
        color: theme('colors.white');
        background-color: theme('colors.primary');

        &::before {
            background-color: theme('colors.primary-600');
        }

        &:focus {
            outline-color: theme('colors.primary-300');
            outline-width: 4px;
        }

        &:hover {
            background-color: theme('colors.primary-600');
        }

        &:active,
        &.active {
            background-color: theme('colors.primary-700');
        }
    }

    .button.button-primary-outline {
        --tw-ring-color: theme('colors.primary');
        --tw-ring-offset-color: theme('colors.primary');

        color: theme('colors.primary');
        box-shadow: 0 0 0 1px var(--tw-ring-color);

        &::before {
            color: theme('colors.white');
            background-color: theme('colors.primary-600');
        }

        &:focus {
            outline-color: theme('colors.primary-300');
            outline-width: 4px;
        }

        &:hover {
            --tw-ring-color: theme('colors.primary-600');
            --tw-ring-offset-color: theme('colors.primary-600');

            color: theme('colors.white');
            background-color: theme('colors.primary-600');
        }

        &:active,
        &.active {
            --tw-ring-color: theme('colors.primary-700');
            --tw-ring-offset-color: theme('colors.primary-700');

            color: theme('colors.white');
            background-color: theme('colors.primary-700');
        }

        &.button-loading {
            color: theme('colors.white');
        }
    }

    .button.button-primary-link {
        text-decoration: underline;
        text-underline-offset: 2px;
        color: theme('colors.primary');

        &::before {
            text-decoration: none;
            color: theme('colors.primary-600');
        }

        &:focus {
            text-decoration: none;
            outline-color: theme('colors.primary-300');
            outline-width: 4px;
        }

        &:hover {
            text-decoration: none;
            color: theme('colors.primary-600');
        }

        &:active,
        &.active {
            text-decoration: none;
            color: theme('colors.primary-700');
        }
    }

    .button.button-dark {
        color: theme('colors.white');
        background-color: theme('colors.nmd-600');

        &::before {
            background-color: theme('colors.green.500');
        }

        &:focus {
            outline-color: theme('colors.nmd-300');
            outline-width: 4px;
        }

        &:hover {
            background-color: theme('colors.green.500');
        }

        &:active,
        &.active {
            background-color: theme('colors.nmd-700');
        }
    }

    .button.button-dark-outline {
        --tw-ring-color: theme('colors.nmd-500');
        --tw-ring-offset-color: theme('colors.nmd-500');

        color: theme('colors.white');
        box-shadow: 0 0 0 1px var(--tw-ring-color);

        &::before {
            color: theme('colors.white');
            background-color: theme('colors.green.600');
        }

        &:focus {
            outline-color: theme('colors.nmd-300');
            outline-width: 4px;
        }

        &:hover {
            --tw-ring-color: theme('colors.green.600');
            --tw-ring-offset-color: theme('colors.green.600');

            color: theme('colors.white');
            background-color: theme('colors.green.600');
        }

        &:active,
        &.active {
            --tw-ring-color: theme('colors.green.700');
            --tw-ring-offset-color: theme('colors.green.700');

            color: theme('colors.white');
            background-color: theme('colors.green.700');
        }

        &.button-loading {
            color: theme('colors.white');
        }
    }

    .button.button-dark-link {
        text-decoration: underline;
        text-underline-offset: 2px;
        color: theme('colors.white');

        &::before {
            text-decoration: none;
            color: theme('colors.white');
        }

        &:focus {
            text-decoration: none;
            outline-color: theme('colors.nmd-300');
            outline-width: 4px;
        }

        &:hover {
            text-decoration: none;
            color: theme('colors.white');
        }

        &:active,
        &.active {
            text-decoration: none;
            color: theme('colors.white');
        }
    }

    .button.button-success {
        color: theme('colors.white');
        background-color: theme('colors.green.500');

        &::before {
            background-color: theme('colors.green.600');
        }

        &:focus {
            outline-color: theme('colors.green.300');
            outline-width: 4px;
        }

        &:hover {
            background-color: theme('colors.green.600');
        }

        &:active,
        &.active {
            background-color: theme('colors.green.700');
        }
    }

    .button.button-success-outline {
        --tw-ring-color: theme('colors.green.500');
        --tw-ring-offset-color: theme('colors.green.500');

        color: theme('colors.green.500');
        box-shadow: 0 0 0 1px var(--tw-ring-color);

        &::before {
            color: theme('colors.white');
            background-color: theme('colors.green.600');
        }

        &:focus {
            outline-color: theme('colors.green.300');
            outline-width: 4px;
        }

        &:hover {
            --tw-ring-color: theme('colors.green.600');
            --tw-ring-offset-color: theme('colors.green.600');

            color: theme('colors.white');
            background-color: theme('colors.green.600');
        }

        &:active,
        &.active {
            --tw-ring-color: theme('colors.green.700');
            --tw-ring-offset-color: theme('colors.green.700');

            color: theme('colors.white');
            background-color: theme('colors.green.700');
        }

        &.button-loading {
            color: theme('colors.white');
        }
    }

    .button.button-success-link {
        text-decoration: underline;
        text-underline-offset: 2px;
        color: theme('colors.green.500');

        &::before {
            text-decoration: none;
            color: theme('colors.green.600');
        }

        &:focus {
            text-decoration: none;
            outline-color: theme('colors.green.300');
            outline-width: 4px;
        }

        &:hover {
            text-decoration: none;
            color: theme('colors.green.600');
        }

        &:active,
        &.active {
            text-decoration: none;
            color: theme('colors.green.700');
        }
    }

    .button.button-danger {
        color: theme('colors.white');
        background-color: theme('colors.red.500');

        &::before {
            background-color: theme('colors.red.600');
        }

        &:focus {
            outline-color: theme('colors.red.300');
            outline-width: 4px;
        }

        &:hover {
            background-color: theme('colors.red.600');
        }

        &:active,
        &.active {
            background-color: theme('colors.red.700');
        }
    }

    .button.button-danger-outline {
        --tw-ring-color: theme('colors.red.500');
        --tw-ring-offset-color: theme('colors.red.500');

        color: theme('colors.red.500');
        box-shadow: 0 0 0 1px var(--tw-ring-color);

        &::before {
            color: theme('colors.white');
            background-color: theme('colors.red.600');
        }

        &:focus {
            outline-color: theme('colors.red.300');
            outline-width: 4px;
        }

        &:hover {
            --tw-ring-color: theme('colors.red.600');
            --tw-ring-offset-color: theme('colors.red.600');

            color: theme('colors.white');
            background-color: theme('colors.red.600');
        }

        &:active,
        &.active {
            --tw-ring-color: theme('colors.red.700');
            --tw-ring-offset-color: theme('colors.red.700');

            color: theme('colors.white');
            background-color: theme('colors.red.700');
        }

        &.button-loading {
            color: theme('colors.white');
        }
    }

    .button.button-danger-link {
        text-decoration: underline;
        text-underline-offset: 2px;
        color: theme('colors.red.500');

        &::before {
            text-decoration: none;
            color: theme('colors.red.600');
        }

        &:focus {
            text-decoration: none;
            outline-color: theme('colors.red.300');
            outline-width: 4px;
        }

        &:hover {
            text-decoration: none;
            color: theme('colors.red.600');
        }

        &:active,
        &.active {
            text-decoration: none;
            color: theme('colors.red.700');
        }
    }

    .button.button-light {
        color: theme('colors.nmd-800');
        background-color: theme('colors.gray-100');

        &::before {
            background-color: theme('colors.gray-200');
        }

        &:focus {
            outline-color: theme('colors.gray-50');
            outline-width: 4px;
        }

        &:hover {
            background-color: theme('colors.gray-200');
        }

        &:active,
        &.active {
            background-color: theme('colors.gray-300');
        }
    }
</style>
